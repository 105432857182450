<template>
  <v-form class="form" ref="form" v-model="valid" v-on:submit.prevent="changePassword">
    <v-col>
      <v-overlay :value="loading" absolute>
        <v-progress-circular
            indeterminate
            size="64"
        ></v-progress-circular>
      </v-overlay>

      <v-col cols="12" md="6" class="formContent">
        <v-card-title class="formTitle">{{ $t('reset_password.form.title') }}</v-card-title>
      </v-col>
      <v-col cols="12" md="6" class="formContent">
        <v-alert
            v-model="ui.alert.enable"
            :dismissible="ui.alert.dismissible"
            :icon="ui.alert.icon"
            :type="ui.alert.type"
            class="mb-6 mt-1 "
            dense
        >
          {{ ui.alert.message }}
        </v-alert>
      </v-col>
      <v-col cols="12" md="6" class="formContent">
        <v-text-field v-model="formData.password" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="showPassword ? 'text' : 'password'"
                      :rules="[rules.required, rules.password_length, rules.password_letter, rules.password_number]" @click:append="showPassword = !showPassword"
                      :label="this.$t('reset_password.form.data.password')"
                      required></v-text-field>
      </v-col>
      <v-col cols="12" md="6" class="formContent">
        <v-text-field v-model="formData.password_confirm" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="showPassword ? 'text' : 'password'"
                      ref="password_confirm"
                      :rules="[rules.required, rules.match]" @click:append="showPassword = !showPassword"
                      :label="this.$t('reset_password.form.data.password_confirm')" required></v-text-field>
      </v-col>
      <v-col cols="12" md="6" class="formContent">
        <v-btn class="connectBtn" @click="changePassword">{{$t('reset_password.button.save')}}</v-btn>
      </v-col>
    </v-col>
  </v-form>
</template>

<script>

export default {
  name: "ResetPassword",
  data() {
    return {
      valid: true, // whether or not the form is valid
      loading: false,
      ui: {
        alert: {
          enable: false,
          dismissible: false,
          icon: 'mdi-check',
          type: 'info',
          message: 'Test'
        }
      },
      formData: {
        password: '',
        password_confirm: ''
      },

      rules: {
        required: value => !!value || this.$t('reset_password.form.rules.required'),
        match: value => (!!value && value) === this.formData.password || this.$t('reset_password.form.rules.password_match'),
        password_length: value => value.length >= 8 || this.$t('reset_password.form.rules.password_length'),
        password_number: value => {
          const pattern = /\d/
          return pattern.test(value) || this.$t('reset_password.form.rules.password_number')
        },
        password_letter : value => {
          const pattern = /[a-zA-Z]/
          return pattern.test(value) || this.$t('reset_password.form.rules.password_letter')
        }
      },
      showPassword: false,
    }
  },
  watch: {
    "formData.password": function () {
      this.$refs.password_confirm.validate()
    }
  },
  methods: {
    alert(type, icon, message, dismissible = true) {
      this.ui.alert.icon = icon
      this.ui.alert.type = type
      this.ui.alert.message = message
      this.ui.alert.dismissible = dismissible
      this.ui.alert.enable = true
    },
    /**
     * Change user password
     * */
    async changePassword() {
      if (this.$refs.form.validate()) {

        this.loading = true

        try {
          await this.axios.post('/reset/password/' + this.$route.params.token, {
            token: this.$route.params.token,
            new_password: this.formData.password,
            confirmed_password: this.formData.password_confirm
          })

          this.alert('success', 'mdi-check-circle-outline', this.$t('reset_password.form.success_message'), true)

          setTimeout(async () =>
              // redirect to Login once password was changed
              await this.$router.push({name: 'Login'}), 1000);

        } catch (e) {
          if (e.response?.status !== undefined) {
            switch (e.response.status) {
                // Invalid params
              case 400:
                this.alert('error', 'mdi-alert-circle-outline', this.$t('reset_password.form.error.invalid_params'), true)
                break

                // Passwords do not match
              case 402:
                this.alert('error', 'mdi-alert-circle-outline', this.$t('reset_password.form.error.password_not_match'), true)
                break

                // Password format invalid
              case 403:
                this.alert('error', 'mdi-alert-circle-outline', this.$t('reset_password.form.error.password_invalid'), true)
                break

                // User was not found
              case 404:
                this.alert('error', 'mdi-alert-circle-outline', this.$t('reset_password.form.error.token_invalid'), true)
                break

                // Invalid method
              case 405:
                this.alert('error', 'mdi-alert-circle-outline', this.$t('reset_password.form.error.invalid_method'), true)
                break

              default:
                this.alert('error', 'mdi-alert-circle-outline', this.$t('reset_password.form.error.unknown'), true)
                break
            }
          }
        } finally {
          this.loading = false
        }
      }
    },
    /**
     * Keyboard enter action
     */
    keyboard_enter_action: function (e) {
      if (e.keyCode === 13) {
        this.changePassword()
      }
    },
    /**
     * Listen keyboard enter request
     */
    listen_keyboard_enter_request: function () {
      document.addEventListener("keydown", this.keyboard_enter_action, false);
    },
    /**
     * Stop listen keyboard enter request
     */
    stop_listen_keyboard_enter_request: function () {
      document.removeEventListener("keydown", this.keyboard_enter_action, false);
    }
  },
  mounted() {
    this.listen_keyboard_enter_request();
  },
  beforeDestroy() {
    this.stop_listen_keyboard_enter_request();
  }
}
</script>

<style lang="scss" scoped>

::v-deep {
  .v-overlay__scrim {
    border-top-left-radius: 10%;
    border-bottom-left-radius: 10%;
  }
}

.form {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  .formContent {
    margin: 0 auto;

    .formTitle {
      padding: 0;
      color: dimgray;
      font-size: 2rem;
      font-weight: bold;
    }

    .forgotten {
      font-size: 80%;
      display: flex;
      justify-content: right;
      color: RGBA(121, 7, 72, 1);
    }

    .v-progress-circular {
      margin: 1rem;
      color: RGBA(121, 7, 72, 1);
    }

    .connectBtn {
      width: 100%;
      padding: 0;
      color: #ffffff;
      font-size: 0.7rem;
      background-color: RGBA(121, 7, 72, 1);
    }

    .newAccount {
      padding: 2% 0 0 0;

      .account {
        color: RGBA(121, 7, 72, 1);

      }
    }

    .account {
      color: RGBA(121, 7, 72, 1);

    }
  }

}
</style>
